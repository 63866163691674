import React from 'react'
import { Navbar, Header, Products, Articles, Footer } from './containers'
import { Section } from './components'

const App = () => {
    return (
        <div>
            <div>
                <Navbar />
                <Header />
            </div>
            <Section />
            <Products />
            <Articles />
            <Footer />
        </div>
    )
}

export default App

import React from 'react';
import { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png';

const Menu = () => (
    <>
        <p><a href="#home">HOME</a></p>
        <p><a href="#products">PRODUCTS</a></p>
        <p><a href='referral.php'>REFERRAL</a></p>
    </>
)

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false)

    return (
        <div className="navbar">
            <div className="navbar-links">
                <div className="navbar-links-logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="navbar-links-container">
                    <Menu />
                </div>
                <div className="navbar-sign">
                    <p><a href='../demo'>DEMO</a></p>
                    <a href='signup.php'>REGISTER</a>
                </div>
                <div className="navbar-menu">
                    {toggleMenu
                        ? <RiCloseLine size = {27} onClick = {() => setToggleMenu(false)}/>
                        : <RiMenu3Line size = {27} onClick = {() => setToggleMenu(true)}/>
                    }
                    {toggleMenu && (
                        <div className="navbar-menu-container scale-up-center">
                            <div className="navbar-menu-container-links">
                                <Menu />
                                <div className="navbar-menu-container-links-sign">
                                    <p><a href='../demo'>DEMO</a></p>
                                    <a href='signup.php'>REGISTER</a>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </div>
    )
}

export default Navbar

import React from 'react';
import Slider from 'react-slick';
import newFeature1 from '../../assets/new-feature1.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Articles = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    return (
        <div className='articles'>
          <h2 className='articles-title text-gr'>Explore our new features</h2>
           <Slider {...settings}>
                {/* <div className="articles-image"> */}
                  <img src={newFeature1} alt="New feature - CBT random questions" />
                {/* </div> */}
                <h1>Next new feature coming up soon...</h1>
           </Slider> 
        </div>
    )
}

export default Articles

import React from 'react'

const Section = () => {
  return (
    <div className='section-break section-padding'>
        <h2 className='text-gr scale-up-center'>Automation of processes achieves maximum profits</h2>
    </div>
  )
}

export default Section
import React from 'react';
import cbtimage from '../../assets/cbt.png';
import lmsimage from '../../assets/lms-image.png';
import smsimage from '../../assets/sms-image.png';
import { FaChevronCircleRight } from 'react-icons/fa';
import Product from '../../components/Product';

const Products = () => {
    return (
        <div className='products section-padding' id='products'>
            <div className="product-cbt">
                <Product
                    image={cbtimage} alttext='image of cbt product'
                    title = 'Computer-based assessment' 
                    content='Use the computer-based test (CBT) engine to optimize your assessment process.'
                />
            </div>
            {/* <div className="card">
                <div className="ml-0 mr-5 card-content scale-up-center">
                    <h2 className='text-gr'>Learning management system</h2>
                    <p>Learners can be adequately engaged using this learning management system. Take teaching beyond the classroom.</p>
                    <div className="card-content-link">
                        <a href='#'><span>Explore</span>{<FaChevronCircleRight color='#142c57' />}</a>
                    </div>
                </div>
                <div className="card-gr card-image flex-order">
                    <img src={lmsimage} alt="" />
                </div>
            </div> */}
            <div className="product-lms">
                <Product
                    image={lmsimage} alttext='image of learning management product'
                    title = 'Learning management system' 
                    content='Learners can be adequately engaged using this learning management system. Take teaching beyond the classroom.'
                />
            </div>
            {/* <div className="card">
                <div className="card-image">
                    <img src={smsimage} alt="" />
                </div>
                <div className="card-content scale-up-center">
                    <h2 className='text-gr'>School management system</h2>
                    <p>You can now comfortably run your school from anywhere, using the school management software.</p>
                    <div className="card-content-link">
                        <a href='#'><span>Explore</span>{<FaChevronCircleRight color='#142c57' />}</a>
                    </div>
                </div>
            </div> */}
            <div className="product-sms">
                <Product
                    image={smsimage} alttext='image of school management product'
                    title = 'School management system' 
                    content='You can now comfortably run your school from anywhere, using the school management software.'
                />
            </div>
        </div>
    )
}

export default Products

import React from 'react'

const Footer = () => {
    return (
        <footer className='footer'>
            <section class="footer-nav">
                <ul>
                    <li><a href='#'>Home</a></li>
                    <li><a href='#'>Products</a></li>
                    <li><a href='mailto:info@satplustech.com'>Support</a></li>
                    <li><a href='signup.php'>Start for free</a></li>
                    <li><a href='referral.php'>Referral</a></li>
                    <li><a href='../demo'>Demo</a></li>
                </ul>      
            </section>
            <section className="copy">
                <span>Copyright &copy; {new Date().getFullYear()} Satplus Technology. All rights reserved.</span>
            </section>
        </footer>
    )
}

export default Footer

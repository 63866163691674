import React from 'react';
import headerImg from '../../assets/header-img.png';

const Header = () => {
    return (
        <div className="header section-padding" id="home">
            <div className="header-content scale-up-center">
                <h1 className="text-gr">
                    Connect with your school. 
                    Achieve your goals.
                </h1>
                <p>
                We are committed to you and your school. Our products will aid and propel
                you to the zenith of your vision in education.
                </p>
                <div className="header-content-input">
                    <a href='signup.php'>START FOR FREE</a>
                </div>
                
            </div>
            <div className="header-image">
                <img src={headerImg} alt="header image" />
            </div>
        </div>
    )
}

export default Header

import React from 'react'
import { FaChevronCircleRight } from 'react-icons/fa'

const Product = ({ image, alttext, title, content }) => {
  return (
    <div className='card'>
        <div className="card-image">
            <img src={image} alt={alttext} />
        </div>
        <div className="card-content scale-up-center">
            <h2 className='text-gr'>{title}</h2>
            <p>{content}</p>
            <div className="card-content-link">
                <a href='#'><span>Explore</span>{<FaChevronCircleRight color='#142c57' />}</a>
            </div>
        </div>
    </div>
  )
}

export default Product